import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import Listing from "../components/listing"
import contentParser from 'gatsby-wpgraphql-inline-images';


const IndexPage = ({data}) => {

	const pluginOptions = {
		wordPressUrl: `https://admin.borgosangiuliano.com/`,
		uploadsUrl: `https://admin.borgosangiuliano.com/wp/wp-content/uploads/`,
	};
	const content = data.wpGraphQl.page.content

	const copertina = data.wpGraphQl.page.acf_wpgraphql_imgCopertina.imgCopertina.imageFile.childImageSharp.fluid 
	return (
  <Layout>
		{/* <img src={data.wordpressPage.acf.img_copertina.source_url} /> */}

		<SEO
				title={data.wpGraphQl.page.seo.title}
			// il component SEO prende description principale da gatsby-config.js (e la inserisce di default se qui è mancante)
			keywords={[`borsosangiuliano`, `festadeborg`, `rimini`]}
				description={data.wpGraphQl.page.seo.metaDesc}
			img={copertina.src} 
		/>

		<Img 
			className="immagine-copertina" 
			fluid={copertina}
			/>

		<main>
			{/* Lo style di main si trova dentro layout.css - ho impostato una CSS GRID */}
			<div>
				
					<h1 dangerouslySetInnerHTML={{ __html: data.wpGraphQl.page.title }} />
					<div>{contentParser({ content }, pluginOptions)}</div>

			</div>
			
			<Listing />

		</main>
  </Layout>
)}

export default IndexPage


export const pageQuery = graphql`
	query PaginaHomeGraphQl {
		wpGraphQl {
      page(id: "cGFnZToy") {
        title
        seo {
          title
          metaDesc
        }
        acf_wpgraphql_imgCopertina {
          imgCopertina {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1400) {
									...GatsbyImageSharpFluid_tracedSVG
                  src
                }
              }
            }
          }
        }
        content
				uri
      }
    }
	}
`