import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'

/* STATIC QUERY */

const LISTING_QUERY = graphql`
query BlogPostListing {
  wpGraphQl {
    menu(id: "TWVudToy") {
      menuItems {
        edges {
          node {
						id
            label
            url
          }
        }
      }
    }
  }
}`
/* STYLE TRAMITE EMOTION */

const Sidebar = styled.aside`

	grid-row-start:1;
	grid-column-start: 2;
	@media (max-width: 912px){
		grid-column-start:1;
		grid-row-start:2;
		width: 90%;
		text-align:center;
	}
  margin: 0 auto;
	height: 100hv;

	.wrapper-listing {
		background-color: #ffba83;
		padding: 20px;
		position: sticky;
		top: 3rem;
		> ul {
			font-size: 0.6rem;
			margin-bottom: 0;
			margin-left: 0;
			list-style: none;
			> li {
				padding-top: 0.6rem;
				margin-bottom: 0.5rem;
				border-top: 1px dashed gray; 
				&:nth-of-type(1){border:none;}
			}
			>li>a { color:#444758; text-decoration: none; }
			>li>a>h4 {
				text-transform: uppercase;
				font-size: 0.9rem;
			}
		}
	}
	
`
/* COMPONENT LISTING */

const Listing = () => (
	<StaticQuery
		query={LISTING_QUERY}
		render={({ wpGraphQl }) => (
			<Sidebar>
				<div className="wrapper-listing">
					<ul className="lista">

						{ wpGraphQl.menu.menuItems.edges.map(({ node }) => {

							const wpurl = `https://admin.borgosangiuliano.com`
							const onlyPath = node.url.replace(wpurl, ``)

							return (
								<li key={node.id}>
									<Link to={`/${onlyPath}/`}>
											<h4>{node.label}</h4>
									</Link>
								</li>
						)})}

					</ul>
				</div>
			</Sidebar >
		)}
	/>
)

export default Listing









/*  QUERY UTILIZZANDO WP REST API Menus

	allWordpressWpApiMenusMenusItems (filter: {name: {eq: "Menu 1"}} ) {
    edges {
      node {
				id
        items {
          title
          object_slug
          object_id
        }
      }
    }
  }

DA METTERE A POSTO QUESTO NON SONO RIUSCITO A FARLO FUNZIONARE:

const Listing = () => (
	<StaticQuery
		query={LISTING_QUERY}
		render={({ allWordpressWpApiMenusMenusItems }) => (
			<Sidebar>
				{allWordpressWpApiMenusMenusItems.edges.map(({ node }) => (
					<ul key={node.id}>
					<li key={node.items.object_id}>
						<Link to={`${node.items.object_url}`}>
						<h2>{node.items.title}</h2>
						</Link>
					</li>
					</ul>
			))}
			</Sidebar >
		)}
	/>
)

*/
